const labels = {
  Quote: {
    Landing: {
      PageTitle: "Homeowners - Landing",
      PageDescription: "Page captures details about the Address.",
    },
    QuickQuote: {
      PageTitle: "Homeowners - Quote Indication",
      PageDescription:
        "Page captures details about applicant and basic coverages.",
    },
  },
  Application: {
    // discuss
    PolicyHolder: {
      PageTitle: "Homeowners - Application - Policy Holder",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    Coverage: {
      PageTitle: "Homeowners - Application - Coverage",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    Property: {
      PageTitle: "Homeowners - Application - Property",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    RiskQualifiers: {
      PageTitle: "Homeowners - Application - Risk Qualifiers",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    Summary: {
      PageTitle: "Homeowners - Application - Summary",
      PageDescription: "Page displays information captures so far.",
    },
  },
  Endorsement: {
    PolicyHolder: {
      PageTitle: "Homeowners - Endorsement - Policy Holder",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    Coverage: {
      PageTitle: "Homeowners - Endorsement - Coverage",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    Property: {
      PageTitle: "Homeowners - Endorsement - Property",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    RiskQualifiers: {
      PageTitle: "Homeowners - Endorsement - Risk Qualifiers",
      PageDescription: "Page captures and validates risks for qualification.",
    },
    Summary: {
      PageTitle: "Homeowners - Endorsement - Summary",
      PageDescription: "Page displays information captures so far.",
    },
  },
  Cancellation: {
    Landing: {
      PageTitle: "Homeowners - Cancellation",
      PageDescription:
        "Page captures details about cancellation of an application.",
      favicon: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/favicon-embark.png`,
    },
  },
  Reinstatement: {
    Landing: {
      PageTitle: "Homeowners - Reinstatement",
      PageDescription:
        "Page captures details about reinstatement of an application.",
      favicon: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/favicon-embark.png`,
    },
  },
  Analytics: {
    Index: {
      PageTitle: "Commercial Auto - Analytics Landing",
      PageDescription:
        "Page displays in depth analytics across various segments..",
    },
    AnalyticsTabs: {
      PolicyAnalytics: "Policy Analytics",
      QuoteAnalytics: "Quote Analytics",
      UnderwriterAnalytics: "Underwriter Analytics",
    },
  },
  Dashboard: {
    AccountHub: {
      PageTitle: "AccountHub",
      PageDescription: "Page displays list of policies",
    },
  },
  Shared: {
    // ReviewForUW: "Risk will be submitted to Underwriter for further review and your Underwriter will contact you shortly. Do you want to proceed ?",
    ReviewForUW: "UNDERWRITING RULES VIOLATED",
    MatchingRules: "Matching rules",
    Login: {
      PageTitle: `EMBARK || Login`,
      PageDescription: "Application login page.",
    },
    QuickPay: {
      PageTitle: `EMBARK || Quick Pay`,
      PageDescription: "Quick Pay page.",
    },
  },
  MyCommunicationHub: {
    MyCommunicationHub: {
      PageTitle: "My Communication Hub",
      PageDescription: "Page displays various threads of the Logged in user.",
    },
  },
  Renewal: {
    BusinessInfo: {
      PageTitle: "Commercial Auto - Business Information",
      PageDescription: "Page captures basic information about business.",
    },
    BusinessLocation: {
      PageTitle: "Commercial Auto - Business Locations",
      PageDescription:
        "Page captures basic information about business locations.",
    },
    Summary: {
      PageTitle: "Commercial Auto - Application Summary",
      PageDescription: "Page displays information captures so far.",
    },
  },
};

export { labels };
